.footer {
  /* background-color: rgb(16, 14, 14); */
  background-color: #2e2e2e;
  color: #999;
}
.footer h2 {
  color: white;
  font-weight: 900;
}
.agree {
  font-size: 12px;
}
.Footer_Tab {
  text-transform: capitalize;
  font-weight: bold;
  color: white;
}
.ft {
  color: white;
}
.ft i {
  color: white;
  padding: 30px;
}
.color_grey {
  color: #999;
}
.footer input {
  height: 40px;
  border: none;
}
.footer button {
  height: 40px;
  border: none;
  position: absolute;
  background-color: brown;
  border: 3px solid brown;
  width: 150px;
  font-weight: bold;
  color: white;
}
