.slider {
  /* background-color: bisque; */
  height: 80vh;
}

.slider {
  display: flex;

  justify-content: center;
  align-items: center;
  height: 100vh; /* set height to the viewport height for demonstration purposes */
}

.content {
  width: 80%;
  text-align: center;
  margin: auto;
}
.content h1 {
  font-size: 55px;
  color: white;
}
.content button {
  width: 30%;
  margin: 20px;
}
.content button:nth-child(1) {
  width: 30%;
  margin: 20px;
  background-color: brown;
}
.Float {
  width: 100%;

  text-align: center;
  /* display: flex;
  justify-content: center;
  align-items: center; */
}
.Float .row .col {
  background-color: white;
  height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 18px;
  color: #df2528;
  border: 1px solid #df2528;
}
.Float .row .col:nth-child(2) {
  border-left: 1px solid #df2528;
}
.Float .row .col:nth-child(1) {
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
}
.dx div {
  background-color: white;
  height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 18px;
  border-radius: 20px;
  width: 80%;
  background-color: #3977bb;
  color: white;
}
.Float ion-icon {
  margin-left: 30px;
}
.m .col ion-icon {
  margin: 30px;
}
.m .col ion-icon {
  /* margin-left: 30px; */
}
.flm {
  position: absolute;
  width: 100%;
  z-index: 100;
  margin-top: -5vh;
}
